































import { Chat } from '@tada-team/tdproto-ts'
import { QMenu, throttle } from 'quasar'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ChatMenu: () => import('@/components/UI/ChatMenu.vue'),
    IconMore: () => import('@tada/icons/dist/IconMore.vue'),
  },
})
export default class Menu extends Vue {
  @Prop({ type: Object, required: true }) private readonly chat!: Chat
  @Prop({ type: Number, default: 0 }) private readonly offset!: number
  @Prop({ type: Boolean }) private readonly showHide?: boolean
  @Prop({ type: Boolean }) private readonly showPin?: boolean
  @Prop({ type: Boolean }) private readonly showSystembot?: boolean

  @Ref() menu!: QMenu

  onScroll = throttle(this.hide, 200)

  private hide () {
    this.menu.hide()
  }
}
